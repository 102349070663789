import { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
const MVContext = createContext();
const MVProvider = ({ children }) => {
  const [mvJsonData, setMvJsonData] = useState({
    partnerCode: 413,
    partnerRef: "cm_9985716",
    name: null,
    phone: null,
    pan: null,
    dateOfBirth: null,
    gender: null,
    bureauPermission: true,
    addressList: [
      {
        pincode: null,
        residenceType: null,
        addressType: null,
      },
      {
        pincode: null,
        residenceType: null,
        addressType: null,
      },
    ],
    declaredIncome: null,
    employmentType: null,
    incomeMode: null,
    emailList: [
      {
        email: null,
        type: "primary_device",
      },
    ],
  });

  return (
    <MVContext.Provider value={{ mvJsonData, setMvJsonData }}>
      {children}
    </MVContext.Provider>
  );
};
export { MVContext, MVProvider };
